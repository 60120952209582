body {
  margin: 0;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.Login-bg {
  /* The image used */
  background-image: url("../public/login_bg.png");
  /* background-color: aquamarine; */
  /* Full height */
  height: 100vh;
  position: relative;
  opacity: 1;
  /* Center and scale the image nicely */
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
