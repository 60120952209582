.sidebar {
  width: 260px;
  flex-shrink: 0;
  background-color: rgba(22, 22, 22, 1);
  height: 100%;
  overflow: auto;
}

* {
  /* padding: 0; */
  /* margin: 0; */
  box-sizing: border-box;
}
.sidebar-item {
  padding: 0.75em 1em;
  display: block;
  transition: background-color 0.15s;
  border-radius: 10px;
  color: black;
}
.sidebar-item:hover {
  color: blue;
}

.sidebar-title {
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
}
.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}
.sidebar-content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  /* color: #fff; */
  text-decoration: none;
}
.sidebar-item.plain:hover {
  text-decoration: underline;
}
.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}

/* html,body{
  height: 100%;
  background: linear-gradient(to top right,#80cbc4, #e1bee7,#80ddea,#d1c4e9);
} */
nav {
  width: 16.526rem;
  position: absolute;
  top: 4rem;
  bottom: 0;
  left: 0;
  background: white;
  color: #004aad;
}
nav ul {
  position: relative;
  list-style-type: none;
}

nav ul li a {
  display: flex;
  align-items: center;
  font-family: arial;
  font-size: 1.2em;
  text-decoration: none;
  text-transform: capitalize;
  color: #004aad;
  padding: 10px 30px;
  height: 50px;
  transition: 0.5s ease;
  border-radius: 0 30px;
}
nav ul li a:hover {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
}

nav ul ul {
  position: absolute;
  left: 250px;
  width: 200px;
  top: 0;
  display: none;
  background: rgba(155, 39, 176, 1);
  z-index: 1000;
  border-radius: 5px;

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 1);
}
nav ul span {
  position: absolute;
  right: 20px;
  font-size: 1.5em;
}
nav ul .dropdown {
  position: relative;
}

nav ul .dropdown:hover > ul {
  display: initial;
}

nav ul .dropdown_two ul {
  position: absolute;
  left: 200px;
  top: 0;
}
nav ul .dropdown_two:hover ul {
  display: initial;
}
nav ul .split ul {
  top: 100px;
}
.inner-content {
  opacity: 0;
  transition: opacity 0.3s ease; /* You can adjust the duration and easing function */
}

.inner-content.show {
  opacity: 1;
}
.temp {
  display: none;
}
.outer-div {
  border: "2px solid black";
  max-height: 40px;
  min-width: 100%;
}
.nested-div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* align-items: center; */
  /* background-color: red; */
  padding: 0.5rem;
  row-gap: 0.5rem;
  padding-left: 1rem;
  /* width: fit-content; */
  min-width: 12rem;
}
.outer-div:hover .wraptest {
  opacity: 1;
}
.wraptest {
  opacity: 0;
  position: "absolute";
  left: 200%;
  top: "0";
  z-index: "40000";
  padding: 1rem;
}

.outerr-div {
  display: flex !important;
  padding: 0.5rem  !important;
  position: relative;
  font-size: 1rem;
  justify-content: space-between;
  cursor: pointer;
  /* transition: all 650ms ease; */
}
.outerr-div:hover{
 padding-left: 3rem;
}
.temp::before{
  content: "";
  position: absolute;
  top: 10%;
  left:5%;
  /* border:0.8rem solid transparent; */
  /* border-bottom-color: #eb3007;; */
  /* border-left-width: 1px; */
  transform: rotate(90deg);
}

.outerr-div:hover .nestedd-div {
  opacity: 1;
  display: flex;
  border: 1px solid #C0C0C0;
  /* padding-left: 1rem; */
}

.wraptestt::before{
  top:0.9rem;
  /* left:-2.5rem; */
  /* border:1.2rem solid transparent; */
  /* border-right-color: #eb3007; */


}
.wraptestt {
  position: absolute;
  top: 0%;  
  left: 95%;
  border-radius: 30px 30px;
  cursor: pointer;
  transition: all 650ms ease;
}

.nestedd-div {
  width: fit-content;
  display: none;
  justify-content: start;
  flex-direction: column;
  background-color: #EEEEEE;
  color:#004AAD;
  z-index: 1000000;
  border:"5px solid gray";
  border-radius: 6px 6px;
  cursor: pointer;
  padding: 0.5rem;
  row-gap: 0.5rem;
  padding-left: 0rem;
  min-width: 12.5rem;
  opacity: 0;
  transition: all 650ms ease;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.30);
  /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20); */

}
.nestedd-div:hover{
  color:#0568ea;
  /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20); */
}
.outerr-div:hover div{
  padding-left: 0.3rem;
  transition: all 0.4s ease;
  color:#0460d8;
  /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20); */
  /* background-color: red; */
 }
 .nestedDiv:hover{
  /* color:#064aa4; */
  margin-left: 0.2rem;
  /* background-color: #808080; */
  /* background-color: rgb(221, 219, 219); */
  border-radius: 50px 50px;
  color:white;



 }
 .transtion{
  /* transition:"all 2s fadein"; */
  cursor: pointer;
 }