.header_profile_container {
  position: relative;

  .header_onclick_container {
    position: absolute;
    right: 0;
    top: 60px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    width: 108px;
    max-width: 150px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    .header_user_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      .header_username {
        margin: 0;
        color: #667080;
        font-size: 16px;
      }

      .header_user_id {
        margin: 0;
        color: #c8d7ec;
        font-size: 12px;
      }
    }

    button {
      color: #fa5252;
      cursor: pointer;
      border-radius: 4px;
      font-weight: 600;
      border: none;
      background-color: rgba(250, 240, 240, 1);
      padding: 0px 18px;
      box-sizing: border-box;
      height: 36px;
    }

    button:hover {
      background-color: rgba(250, 234, 234, 1);
    }
  }
}
