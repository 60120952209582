.App {
  text-align: center;
}
#root {
  font-family: "Figtree", sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
[class*="mantine"] {
  font-family: "Figtree", sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box {
  width: 40px;
  height: 30px;
}

.box .group {
  width: 215px;
  height: 30px;
}

.box .overlap-group {
  position: relative;
  width: 211px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 10px;
}

.box .text-wrapper {
  position: absolute;
  top: 8px;
  left: 59px;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.box .overlap {
  position: absolute;
  width: 35px;
  height: 30px;
  top: 5px;
  left: 11px;
}

.box .rectangle {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 2px;
  left: 0;
  background-color: #ac38e3;
  border-radius: 15px;
}

.box .div {
  position: absolute;
  top: 9px;
  left: 10px;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.box .rectangle-2 {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  left: 19px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 3px solid;
  border-color: #6cba09;
}
